@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap')
// $base-font-size: 112.5
$base-font-size: 100
$line-height: 1.45

@mixin line-height($number)
  line-height: #{ $number * $line-height + 'rem'}

@mixin margin-top($number)
  margin-top: #{ $number * $line-height + 'rem'}

@mixin margin-bottom($number)
  margin-bottom: #{ $number * $line-height + 'rem'}

*
  @include line-height(1)

html, body
  padding: 0
  margin: 0
  max-width: 100wv
html
  font-size: #{$base-font-size + '%'}
  font-family: "Open Sans", serif
  font-optical-sizing: auto
  font-style: normal
  font-variation-settings: "wdth" 100

body
  display: grid
  grid-template-columns: minmax(2rem, 8rem) auto 1fr
  article
    grid-column: 2 / 3
    padding-top: 3rem
    padding-bottom: 6rem
  .images
    grid-column: 3 / 4
    background-image: url("img/cozzygiraffe.png")
    background-repeat: no-repeat
    background-position: 100% 100%
    background-size: 200px auto

h1
  font-size: 3rem
  @include margin-bottom(0.5)
h2
  font-size: 1.4rem
  @include margin-bottom(0.5)
  position: relative
  &::after
    content: ''
    position: absolute
    bottom: 0
    left: 0
    right: 0
    width: 100%
    border-bottom: 1px solid black

ul, ol
  padding-left: 0
  list-style-position: outside
  @include margin-top(0)
  li
    @include margin-bottom(0)

article
  width: 750px
  header
    position: relative
    text-align: center
    img
      position: absolute
      right: -5rem
      bottom: -3rem
      max-height: 300px
      width: auto
      z-index: 2
      transform: rotateZ(7deg)
    p
      font-style: italic
      text-align: justify
      text-justify: inter-word
      padding: 0 5rem

.experience-header, .education-item
  display: flex
  justify-content: space-between

.education-date
  white-space: nowrap

a
  color: black
  text-decoration: none
  :visited
    color: black
    text-decoration: none

@media only screen and (max-width: 1200px)
  body
    grid-template-columns: 1fr
    article
      max-width: 750px
      width: auto
      grid-column: 1 / 2
    .images
      background-image: none

@media only screen and (max-width: 1200px) and (min-width: 769px)
  article
    margin: 0 5rem

@media only screen and (max-width: 768px)
  header
    img
      display: none
  html
    article
      margin: 0 2rem
      ul, ol
        padding-left: 0
      header
        h1
          line-height: 0.8em
        p
          padding: 0
