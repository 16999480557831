@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap";
* {
  line-height: 1.45rem;
}

html, body {
  max-width: 100wv;
  margin: 0;
  padding: 0;
}

html {
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100;
  font-family: Open Sans, serif;
  font-size: 100%;
  font-style: normal;
}

body {
  grid-template-columns: minmax(2rem, 8rem) auto 1fr;
  display: grid;
}

body article {
  grid-column: 2 / 3;
  padding-top: 3rem;
  padding-bottom: 6rem;
}

body .images {
  background-image: url("cozzygiraffe.65ca2bd9.png");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 200px;
  grid-column: 3 / 4;
}

h1 {
  margin-bottom: .725rem;
  font-size: 3rem;
}

h2 {
  margin-bottom: .725rem;
  font-size: 1.4rem;
  position: relative;
}

h2:after {
  content: "";
  border-bottom: 1px solid #000;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

ul, ol {
  margin-top: 0;
  padding-left: 0;
  list-style-position: outside;
}

ul li, ol li {
  margin-bottom: 0;
}

article {
  width: 750px;
}

article header {
  text-align: center;
  position: relative;
}

article header img {
  z-index: 2;
  width: auto;
  max-height: 300px;
  position: absolute;
  bottom: -3rem;
  right: -5rem;
  transform: rotateZ(7deg);
}

article header p {
  text-align: justify;
  text-justify: inter-word;
  padding: 0 5rem;
  font-style: italic;
}

.experience-header, .education-item {
  justify-content: space-between;
  display: flex;
}

.education-date {
  white-space: nowrap;
}

a, a :visited {
  color: #000;
  text-decoration: none;
}

@media only screen and (width <= 1200px) {
  body {
    grid-template-columns: 1fr;
  }

  body article {
    grid-column: 1 / 2;
    width: auto;
    max-width: 750px;
  }

  body .images {
    background-image: none;
  }
}

@media only screen and (width <= 1200px) and (width >= 769px) {
  article {
    margin: 0 5rem;
  }
}

@media only screen and (width <= 768px) {
  header img {
    display: none;
  }

  html article {
    margin: 0 2rem;
  }

  html article ul, html article ol {
    padding-left: 0;
  }

  html article header h1 {
    line-height: .8em;
  }

  html article header p {
    padding: 0;
  }
}
/*# sourceMappingURL=index.f373385a.css.map */
